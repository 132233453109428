import HomePage from "HomePage";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";

// login option 3 routing
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/admin",
      element: <AuthLogin3 />,
    },
  ],
};

export default AuthenticationRoutes;
