const HomePage = () => {
  return (
    <>
      {/* <div id="loading">
        <img src="./image/siteloader.gif" alt="" />
      </div> */}

<header class="site-header site-header--menu-right px-7 px-lg-10 z-index-99 site-header--absolute">
        <div class="container-fluid">
          <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0">
            <div class="brand-logo mt-3 mt-md-0 mb-5">
              <a href="/">
                <img src="image/logo.png" alt="" class="light-version-logo" />
                <img src="image/logo.png" alt="" class="dark-version-logo" />
              </a>
            </div>
          </nav>
        </div>
      </header>
      <div class="position-relative pt-25 pt-lg-8 pb-13 pb-lg-25">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div
              class="col-xl-6 col-lg-6 col-md-7 col-xs-9"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div class="l3-content-image-5 mt-5 mt-lg-0">
                <div class="img-1">
                  <img class="w-100" src="./image/banner.png" alt="" />
                </div>
                <div class="img-3">
                  <img
                    class="w-100"
                    src="./image/big-green-circle-img.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-5 col-lg-6 col-md-7 col-xs-10"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div class="pl-lg-15 pt-11 pt-lg-0">
                <h2 class="font-size-8 mb-8">
                  Welcome To
                  <br /> <span class="color-red font-size-10">60 Strong</span>
                </h2>
                <p class="font-size-6 mb-0">
                  Get ready to take control of your health, mind, and financial
                  strength in 60 days. Join our community for the next 60 days
                  to help you achieve a balance of wellness and financial
                  discipline that will shape your future goals, mindset and set
                  you up with good habits for life.{" "}
                </p>
                <div class="mt-13">
                  <a class="d-block mb-6" href="https://apps.apple.com/us/app/60-strong/id1633390078">
                    <img src="./image/app-store-img.png" alt="" />
                  </a>
                  <a class="d-block mb-6" href="https://play.google.com/store/apps/details?id=com.infinitetechco.sixtystrong">
                    <img src="./image/play-store-img.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-14 pt-md-18 pt-lg-26 pb-14 pb-md-18 pb-lg-26 bg-default-6">
        <div class="container">
          <div class="row justify-content-center justify-content-lg-start">
            <div class="col-xl-6 col-lg-7 col-md-8">
              <div
                class="mb-0 px-xs-15 px-md-0 mb-10 text-center text-lg-left"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-once="true"
              >
                <h2 class="font-size-10">
                  How <span class="color-red">60 Strong</span> works
                </h2>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-lg-7 col-md-8 order-2 order-lg-1">
              <div class="">
                <div class="row justify-content-center">
                  <div class="col-sm-6 col-xs-7">
                    <div
                      class="pt-13 pt-lg-15 pr-sm-0 pr-xs-10"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-once="true"
                    >
                      <div class="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7">
                        <i class="fa fa-clock"></i>
                      </div>
                      <div class="mt-12 pr-lg-10 pr-md-0 pr-xs-8">
                        <h4 class="font-size-7 mb-5">Dailly Reminders</h4>
                        <p class="font-size-5 mb-0">
                          Set customised reminders to help you stay on track
                          every day.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-7">
                    <div
                      class="pt-13 pt-lg-15 pr-sm-0 pr-xs-10"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-delay="300"
                      data-aos-once="true"
                    >
                      <div class="square-60 bg-sunset shadow-sunset rounded-10 text-white font-size-7">
                        <i class="fa fa-chart-bar"></i>
                      </div>
                      <div class="mt-12 pr-lg-10 pr-md-0 pr-xs-8">
                        <h4 class="font-size-7 mb-5">Track Progress</h4>
                        <p class="font-size-5 mb-0">
                          Easily access how you are tracking with a daily
                          progress analysis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-7">
                    <div
                      class="pt-13 pt-lg-15 pr-sm-0 pr-xs-10"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-delay="600"
                      data-aos-once="true"
                    >
                      <div class="square-60 bg-light-green shadow-light-green rounded-10 text-white font-size-7">
                        <img src="./image/dollar.svg" width="24" height="24" />
                        <i class="fa fa-comment-dollar"></i>
                      </div>
                      <div class="mt-12 pr-lg-10 pr-md-0 pr-xs-8">
                        <h4 class="font-size-7 mb-5">Daily Savings</h4>
                        <p class="font-size-5 mb-0">
                          Monitor your savings balance to see your wealth
                          growing daily.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-7">
                    <div
                      class="pt-13 pt-lg-15 pr-sm-0 pr-xs-10"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-delay="900"
                      data-aos-once="true"
                    >
                      <div class="square-60 bg-dodger-blue-1 shadow-dodger-blue-1 rounded-10 text-white font-size-7">
                        <i class="fa-arrow-rotate-right"></i>
                        <img src="./image/replay.svg" width="24" height="24" />
                      </div>
                      <div class="mt-12 pr-lg-10 pr-md-0 pr-xs-8">
                        <h4 class="font-size-7 mb-5">Accountability</h4>
                        <p class="font-size-5 mb-0">
                          If you miss any of your daily tasks, you will start
                          all over again. This only works if you complete the
                          program correctly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-8 order-1 order-lg-2">
              <div
                class="mt-lg-16 mr-lg-n20 mr-xl-n25"
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img class="w-100" src="./image/image1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative">
        <div class="container">
          <div class="row align-items-center justify-content-center justify-content-lg-start">
            <div class="col-xl-5 col-xs-4">
              <div class="l6-content-image-group">
                <div class="img-1">
                  <img
                    class="w-100"
                    src="./image/dot-pattern-blue.png"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration="500"
                    data-aos-delay="300"
                    data-aos-once="true"
                  />
                </div>
                <div class="img-2">
                  <img
                    class="w-100"
                    src="./image/share-progress.png"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-once="true"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
              <div class="pt-7 pb-7 pb-lg-17 pl-sm-5 pl-md-9 pl-lg-10">
                <h2
                  class="font-size-11 mb-9"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  Share Progress <br class="d-none d-xs-block" /> with your
                  friends
                </h2>
                <p
                  class="font-size-7 mb-0 pr-xl-13"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  Let your friends know about your 60 Strong progress and
                  achievements by sharing your transformation with them on
                  social media. Encourage your friends to see if they can last
                  the distance and see what transformation they can achieve.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-14 pb-15 pt-md-17 pb-md-19 pt-lg-25 pb-lg-26 bg-default-7"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8 col-md-10">
              <div class="text-center dark-mode-texts mb-8 mb-lg-18">
                <h2 class="font-size-11 color-white">Inside our app</h2>
                <p class="font-size-7 mb-0 opacity-7">
                  Get ready to take control of your health, mind, and financial
                  strength in 60 days.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="screenshot-slider-wrapper mb-5">
          <div class="screenshot-slider">
            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-1.png" alt="" />
              </div>
            </div>
            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-2.png" alt="" />
              </div>
            </div>

            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-3.png" alt="" />
              </div>
            </div>

            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-4.png" alt="" />
              </div>
            </div>

            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-5.png" alt="" />
              </div>
            </div>

            <div class="single-slide focus-reset">
              <div class="screenshot-image">
                <img src="./image/screenshot-6.png" alt="" />
              </div>
            </div>
          </div>
          <div class="phone-bg-img">
            <img src="./image/mobile-frame.png" alt="" />
          </div>
        </div>
        <div class="screenshots-dots pt-5 pt-md-10"></div>
      </div>
      <footer>
        <div class="bg-default-6 pt-13 pb-7 position-relative">
          <div class="container">
            <div class="row">
              <div class="col-12 align-items-center justify-content-between">
                <div class="mb-10 mb-lg-0">
                  <ul class="footer-social-link d-flex align-items-center justify-content-center list-unstyled flex-wrap mb-10">
                    <li>
                      <a
                        class="text-default-color-2 gr-hover-text-blue-3 pr-10"
                        href="privacy_policy.html"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        class="text-default-color-2 gr-hover-text-blue-3 pr-10"
                        href="terms_condition.html"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        class="text-default-color-2 gr-hover-text-blue-3"
                        href="faq.html"
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
