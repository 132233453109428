// assets
import { IconLogin, IconUser, IconGrowth } from "@tabler/icons";

// constant
const icons = {
  IconLogin,
  IconUser,
  IconGrowth,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  title: "",
  caption: "",
  type: "group",
  children: [
    {
      id: "user",
      title: "Users",
      type: "item",
      url: "/admin/user",
      target: true,
      icon: icons.IconUser,
      breadcrumbs: false,
    },
    {
      id: "logout",
      title: "Logout",
      type: "item",
      target: true,
      icon: icons.IconLogin,
      breadcrumbs: false,
    },
  ],
};

export default pages;
