import HomePage from "HomePage";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const UserDefault = Loadable(lazy(() => import("views/pages/Users")));
const ProgressDefault = Loadable(lazy(() => import("views/pages/Progress")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/admin",
  element: <MainLayout />,
  children: [
    {
      path: "/admin/user",
      element: <UserDefault />,
    },
    {
      path: "/admin/user/progress/:id",
      element: <ProgressDefault />,
    },
  ],
};

export default MainRoutes;
