import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

// const firebaseConfig = {
//   apiKey: "AIzaSyB-IJuRVPjpXS-6sSB2c_rb6GpwX-3GFy4",

//   authDomain: "sixty-strong-dev.firebaseapp.com",

//   projectId: "sixty-strong-dev",

//   storageBucket: "sixty-strong-dev.appspot.com",

//   messagingSenderId: "624706926688",

//   appId: "1:624706926688:web:a8e36683ea5d839da4eb05",

//   measurementId: "G-9N4ZNY3GS1",
// };



const firebaseConfig = {
  apiKey: "AIzaSyATTvZgOLCxmLEaXdgeUNPBWbuYC1v95ts",
  authDomain: "sixty-strong.firebaseapp.com",
  projectId: "sixty-strong",
  storageBucket: "sixty-strong.appspot.com",
  messagingSenderId: "317784605282",
  appId: "1:317784605282:web:763086ed3a5a5bbb00473b",
  measurementId: "G-BWSMYH87X9",
};


// export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth();

export default firebaseConfig;
