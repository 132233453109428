// material-ui

// import logo from "assets/images/logo.svg";
import logo from "assets/images/logo.png";

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
  return (
    <div>
      <img
        src={logo}
        alt="60 Strong"
        width={props.width ? props.width : "50"}
      />
    </div>
  );
};

export default Logo;
